import React from 'react';

function App() {
  return (
    <div className="container-fluid d-flex flex-column" style={{
      height: "100vh"
    }}>
      <div className="row align-items-center d-flex flex-grow-1">
        <div className="col-12">
          <div className="row">
            <div className="col-12 text-center pb-4">
              <img src="/eon_logo.png" width="112px" alt="" />
            </div>
            <div className="col-12 text-center">
              <p className="eon-text">
                Az E.ON Nyuszmegálló alkalmazás kipróbálásához kérjük mobil eszközről keresd fel ezt az oldalt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
