import React from 'react';
import ReactDOM from 'react-dom';
// import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import is from 'is_js';

import './style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// const options = {
//   autoConfig: true,
//   debug: false,
// };

// ReactPixel.init('1394264140922541', undefined, options);

ReactGA.initialize('UA-196833457-1', {
  debug: true,
});

const isDesktop = is.desktop();

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

setTimeout(() => {
  const el = document.getElementById("loading");
  // ReactPixel.pageView();
  ReactGA.pageview(window.location.pathname + window.location.search)

  if (el && isDesktop) {
    el.classList.add("d-none");
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  } else {
    window.location.replace("https://arweb.app/?zid=z%2FRzBo1c&rs=0&menu=0&toolbar=0")
  }
}, getRandomNumber(1000, 3000))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
